// docs/AdminDocs.js
import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Form, 
  Button, 
  Alert, 
  Modal, 
  Spinner,
  Row,
  Col,
  ListGroup
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AdminDocs.css'; // Import the updated CSS

const AdminDocs = () => {
  const [articles, setArticles] = useState([]); // For listing mode
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [permalink, setPermalink] = useState('');
  const [message, setMessage] = useState({ type: '', text: '' });
  const [isEditMode, setIsEditMode] = useState(false);
  const [articleId, setArticleId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHtmlMode, setIsHtmlMode] = useState(false); // State for HTML mode

  // New state variables for Add Article Modal
  const [showAddModal, setShowAddModal] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [addModalLoading, setAddModalLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Utility function to generate permalink from title
  const generatePermalink = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, '') // Remove invalid characters
      .replace(/\s+/g, '-')         // Replace spaces with dashes
      .replace(/-+/g, '-');         // Replace multiple dashes with single dash
  };

  // Parse query parameters to check if in edit mode
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const idParam = params.get('id');
    if (idParam) {
      const id = parseInt(idParam, 10);
      if (!isNaN(id)) {
        setIsEditMode(true);
        fetchArticle(id);
      } else {
        setMessage({ type: 'danger', text: 'Invalid article ID.' });
        setIsEditMode(false);
        fetchAllArticles();
      }
    } else {
      setIsEditMode(false);
      fetchAllArticles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // Fetch all articles for listing mode
  const fetchAllArticles = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://nodecodestudio.com/backend/docs_endpoint.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch articles');
      }
      const data = await response.json();
      setArticles(data);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setMessage({ type: 'danger', text: error.message });
    } finally {
      setLoading(false);
    }
  };

  // Fetch existing article data for editing by ID
  const fetchArticle = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`https://nodecodestudio.com/backend/docs_endpoint.php?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch the article');
      }
      const data = await response.json();
      if (data.length === 0) {
        throw new Error('Article not found');
      }
      const articleData = data[0];
      setTitle(articleData.title);
      setContent(articleData.content);
      setCategory(articleData.category);
      setPermalink(articleData.permalink);
      setArticleId(articleData.id);
    } catch (error) {
      console.error('Error fetching article:', error);
      setMessage({ type: 'danger', text: error.message });
      setIsEditMode(false);
      fetchAllArticles();
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission for adding or editing
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Determine the request method and URL
      const method = isEditMode ? 'PUT' : 'POST';
      const url = isEditMode 
        ? `https://nodecodestudio.com/backend/docs_endpoint.php?id=${articleId}` 
        : 'https://nodecodestudio.com/backend/docs_endpoint.php';
    
      // Send the request with only the article details in the body
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title,
          content,
          category,
          permalink,
          user_id: localStorage.getItem('user_id') || 1
        })
      });
    
      const result = await response.json();
    
      if (response.ok) {
        setMessage({ type: 'success', text: isEditMode ? 'Document updated successfully!' : 'Document added successfully!' });
        if (!isEditMode) {
          // Clear form fields only if adding a new document
          setTitle('');
          setContent('');
          setCategory('');
          setPermalink('');
        } else {
          // Refresh articles after editing
          fetchAllArticles();
        }
      } else {
        setMessage({ type: 'danger', text: result.error || 'Failed to submit the document' });
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage({ type: 'danger', text: 'An error occurred while submitting the document' });
    } finally {
      setLoading(false);
    }
  };

  // Handle article deletion
  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://nodecodestudio.com/backend/docs_endpoint.php?id=${articleId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const result = await response.json();

      if (response.ok) {
        setMessage({ type: 'success', text: 'Document deleted successfully!' });
        // Redirect to admin-docs without query parameters
        navigate('/admin-docs');
        // Refresh articles after deletion
        fetchAllArticles();
      } else {
        setMessage({ type: 'danger', text: result.error || 'Failed to delete document' });
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage({ type: 'danger', text: 'An error occurred while deleting the document' });
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };

  // Handle image upload
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);

    try {
      setLoading(true);
      const response = await fetch('https://nodecodestudio.com/backend/upload_image.php', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Image upload failed');
      }

      const data = await response.json();
      if (data.url) {
        // Insert image URL into content
        const imageMarkdown = `![${file.name}](${data.url})`;
        setContent(prevContent => `${prevContent}\n\n${imageMarkdown}\n\n`);
        setMessage({ type: 'success', text: 'Image uploaded and inserted successfully!' });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setMessage({ type: 'danger', text: error.message });
    } finally {
      setLoading(false);
      // Reset file input
      e.target.value = null;
    }
  };

  // Handle document upload (similar to image upload)
  const handleDocumentUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('document', file);

    try {
      setLoading(true);
      const response = await fetch('https://nodecodestudio.com/backend/upload_document.php', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Document upload failed');
      }

      const data = await response.json();
      if (data.url) {
        // Insert document link into content
        const docLink = `[${file.name}](${data.url})`;
        setContent(prevContent => `${prevContent}\n\n${docLink}\n\n`);
        setMessage({ type: 'success', text: 'Document uploaded and inserted successfully!' });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error uploading document:', error);
      setMessage({ type: 'danger', text: error.message });
    } finally {
      setLoading(false);
      // Reset file input
      e.target.value = null;
    }
  };

  // Toggle HTML mode
  const toggleHtmlMode = () => {
    setIsHtmlMode(!isHtmlMode);
  };

  // Handle raw HTML editing
  const handleHtmlChange = (e) => {
    setContent(e.target.value);
  };

  // Handle Add Article Modal Submission
  const handleAddArticleSubmit = async (e) => {
    e.preventDefault();
    setAddModalLoading(true);
    
    const permalink = generatePermalink(newTitle);
    
    try {
      const response = await fetch('https://nodecodestudio.com/backend/docs_endpoint.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title: newTitle,
          permalink: permalink,
          content: '',      // Initialize with empty content
          category: '',     // Initialize with empty category
          user_id: localStorage.getItem('user_id') || 1
        })
      });
      
      const result = await response.json();
      
      if (response.ok) {
        // Assuming the backend returns the new article ID
        const newArticleId = result.id;
        setMessage({ type: 'success', text: 'Article created successfully!' });
        setShowAddModal(false);
        setNewTitle('');
        
        // Navigate to edit mode for the new article using ID
        navigate(`/admin-docs?id=${newArticleId}`);
      } else {
        setMessage({ type: 'danger', text: result.error || 'Failed to add article' });
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage({ type: 'danger', text: 'An error occurred while adding the article' });
    } finally {
      setAddModalLoading(false);
    }
  };

  // Render Add Article Modal
  const renderAddArticleModal = () => (
    <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Article</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addModalLoading && (
          <div className="text-center my-3">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {!addModalLoading && (
          <Form onSubmit={handleAddArticleSubmit}>
            <Form.Group controlId="formNewTitle" className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control 
                type="text" 
                value={newTitle} 
                onChange={(e) => setNewTitle(e.target.value)} 
                placeholder="Enter article title" 
                required 
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Article
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );

  // Render Listing Mode
  const renderListing = () => (
    <Container>
      <Row className="mb-4">
        <Col className="list-content-name">
          <h2>All Articles</h2>
        </Col>
        <Col className="text-end">
          <Button 
            variant="success" 
            onClick={() => setShowAddModal(true)}
          >
            Add New Article
          </Button>
        </Col>
      </Row>
      {loading && (
        <div className="text-center my-3">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {message.text && (
        <Alert variant={message.type} onClose={() => setMessage({ type: '', text: '' })} dismissible>
          {message.text}
        </Alert>
      )}
      <ListGroup>
        {articles.map(article => (
          <ListGroup.Item key={article.id} className="list-group-docs-contain edit-articles-width d-flex justify-content-between align-items-center">
            <div>
              <strong>{article.title}</strong> <span className="text-muted">({article.category})</span>
            </div>
            <Button variant="primary" onClick={() => navigate(`/admin-docs?id=${article.id}`)}>
              Edit
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );

  // Render Edit/Create Mode
  const renderForm = () => (
    <Container>
      {loading && (
        <div className="text-center my-3">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <div className="admin-content">
        {/* Sidebar */}
        <div className="admin-sidebar">
          <h2 className="mb-4 text-center">{isEditMode ? 'Edit Article' : 'Create New Article'}</h2>
          {message.text && (
            <Alert variant={message.type} onClose={() => setMessage({ type: '', text: '' })} dismissible>
              {message.text}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formTitle" className="mb-3">
              <Form.Label className="form-label-admin">Title</Form.Label>
              <Form.Control 
                type="text" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
                placeholder="Enter title" 
                required 
              />
            </Form.Group>

            <Form.Group controlId="formCategory" className="mb-3">
              <Form.Label className="form-label-admin">Category</Form.Label>
              <Form.Control 
                type="text" 
                value={category} 
                onChange={(e) => setCategory(e.target.value)} 
                placeholder="Enter category" 
                required 
              />
            </Form.Group>

            <Form.Group controlId="formPermalink" className="mb-3">
              <Form.Label className="form-label-admin">Permalink</Form.Label>
              <Form.Control 
                type="text" 
                value={permalink} 
                onChange={(e) => setPermalink(e.target.value)} 
                placeholder="Enter permalink (e.g., about-node-code-studio)" 
                required 
              />
            </Form.Group>

            <Form.Group controlId="formImageUpload" className="mb-3">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control 
                type="file" 
                accept="image/*" 
                onChange={handleImageUpload} 
              />
            </Form.Group>

            <div className="d-flex flex-column">
              <Button variant="primary" type="submit" className="mb-2" disabled={loading}>
                {isEditMode ? 'Update Article' : 'Add Article'}
              </Button>
              {isEditMode && (
                <Button 
                  variant="danger" 
                  onClick={() => setShowDeleteModal(true)}
                  disabled={loading}
                >
                  Delete Article
                </Button>
              )}
            </div>
          </Form>
        </div>

        {/* Content Panel */}
        <div className="content-panel">
          <Row className="list-group-docs-contain d-flex justify-content-between align-items-center mb-2">
            <Col className="list-content-name">
              <Form.Label>Content</Form.Label>
            </Col>
            <Col className="text-end list-content-name">
              <Button variant="secondary" size="sm" onClick={toggleHtmlMode}>
                {isHtmlMode ? 'Switch to WYSIWYG' : 'Switch to HTML'}
              </Button>
            </Col>
          </Row>
          {isHtmlMode ? (
            <Form.Control 
              as="textarea" 
              rows={15} 
              value={content} 
              onChange={handleHtmlChange} 
              placeholder="Enter raw HTML content here..."
            />
          ) : (
            <ReactQuill 
              theme="snow"
              value={content}
              onChange={setContent}
              placeholder="Enter article content here..."
              modules={AdminDocs.modules}
              formats={AdminDocs.formats}
            />
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this article? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );

  return (
    <div className="admin-container">
      {isEditMode || isEditMode === false ? (
        isEditMode ? renderForm() : renderListing()
      ) : (
        <div className="text-center my-5">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {renderAddArticleModal()}
    </div>
  );
};

// React Quill modules and formats
AdminDocs.modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
};

AdminDocs.formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

export default AdminDocs;
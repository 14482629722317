// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
 

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <Container>
        <div className="footer-content">
        <Link to="/tos" className="footer-link">
            TOS
          </Link>
          <Link to="/privacy" className="footer-link">
            Privacy
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
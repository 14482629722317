// App.js
import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
import './App.css';
import { Navbar, Nav } from 'react-bootstrap';
import { AuthProvider } from './AuthContext'; // Import AuthProvider
import Footer from './Footer'; // Import Footer component
import Docs from './docs/Docs';
import AdminDocs from './docs/AdminDocs';
const LoggedIn = React.lazy(() => import('./LoggedIn'));
const LoggedOut = React.lazy(() => import('./LoggedOut'));
const NodeCreator = React.lazy(() => import('./NodeCreator'));
const ApiKeys = React.lazy(() => import('./apiKeys'));
const MarketplaceFrontend = React.lazy(() => import('./marketplaceFront'));
const MarketplaceDetail = React.lazy(() => import('./marketplaceDetail'));
const SharedBoardsDisplay = React.lazy(() => import('./SharedBoardsDisplay'));
const SharedBoardDetail = React.lazy(() => import('./SharedBoardDetail'));
const SharedBoardsFront = React.lazy(() => import('./SharedBoardsFront'));
const NodeAgentChatBoard = React.lazy(() => import('./NodeAgentChatBoard'));
const AgentBoardList = React.lazy(() => import('./AgentBoardList'));
const SharedBoardFrontDetail = React.lazy(() => import('./SharedBoardFrontDetail'));
const NodeCreatorHeadless = React.lazy(() => import('./NodeCreatorHeadless'));
const Privacy = React.lazy(() => import('./Privacy')); // Lazy load Privacy component
const TermsOfService = React.lazy(() => import('./TermsOfService')); // Lazy load TOS component

// eslint-disable-next-line
import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer';

const App = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const [userAccess, setUserAccess] = useState(localStorage.getItem('userAccess') || '');
  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close overlay when a link is clicked
  };

  // Separate component for consistent navigation links
  const NavigationLinks = () => (
    <>
      {!isAuthenticated ? (
        <>
            <Nav.Link as={Link} to="/docs" onClick={handleLinkClick} className="top-nav-button">
      Docs
    </Nav.Link>
          <Nav.Link as={Link} to="/marketplace" onClick={handleLinkClick} className="top-nav-button">
            Apis
          </Nav.Link>
          <Nav.Link as={Link} to="/agents" onClick={handleLinkClick} className="top-nav-button">
            Solutions
          </Nav.Link>
          <Nav.Link
            onClick={() => { loginWithRedirect(); handleLinkClick(); }}
            className="top-nav-button"
          >
            Login
          </Nav.Link>
        </>
      ) : (
        <>
            <Nav.Link as={Link} to="/docs" onClick={handleLinkClick} className="top-nav-button">
      Docs
    </Nav.Link>
          <Nav.Link as={Link} to="/" onClick={handleLinkClick} className="top-nav-button">
            Boards
          </Nav.Link>
          <Nav.Link as={Link} to="/chat-board" onClick={handleLinkClick} className="top-nav-button">
            Chat
          </Nav.Link>
          <Nav.Link as={Link} to="/apikeys" onClick={handleLinkClick} className="top-nav-button">
            Keys
          </Nav.Link>
          <Nav.Link
          href="https://github.com/nikaskeba/Node-Code-Studio/issues"
          target="_blank"
          onClick={handleLinkClick}
          className="top-nav-button"
        >
          Help
        </Nav.Link>
          <Nav.Link
            onClick={() => { 
              logout({ returnTo: 'https://nodecodestudio.com' }); // Redirects to NodeCodeStudio.com
              handleLinkClick(); 
            }}
            className="top-nav-button"
          >
            Logout
          </Nav.Link>
        </>
      )}
    </>
  );

  return (
    <Router>
      <div className="app-wrapper">
        {/* Navbar */}
        <div className="navbar-wrapper">
          <Navbar expand="lg" className="app-navbar">
            <div className="navbar-content">
              <Navbar.Brand as={Link} to="/">
                <img src="logowhite.png" alt="Logo" className="header-logo" />
              </Navbar.Brand>
              <Navbar.Toggle onClick={toggleOverlay} className="navbar-toggler">
                <div className="navbar-toggler-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Navbar.Toggle>
              <Navbar.Collapse className="navbar-collapse">
                <Nav className="ml-auto d-flex align-items-center">
                  <NavigationLinks />
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>

        {/* Overlay Menu */}
        <div className={`overlay ${isOpen ? 'open' : ''}`}>
          <a href="#!" className="closebtn" onClick={toggleOverlay}>&times;</a>
          <div className="overlay-content">
            <NavigationLinks />
          </div>
        </div>

        {/* Main Content */}
        <div className="content-wrap">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={isAuthenticated ? <LoggedIn /> : <LoggedOut />} />
              <Route path="/apikeys" element={isAuthenticated ? <ApiKeys /> : <LoggedOut />} />
              <Route path="/node-creator" element={isAuthenticated ? <NodeCreator /> : <LoggedOut />} />
              {isAuthenticated && userAccess === 'advanced' && (
    <Route path="/admin-docs" element={<AdminDocs />} />
  )}
     <Route path="/docs" element={<Docs />} />
              <Route path="/marketplace" element={<MarketplaceFrontend />} />
              <Route path="/agents" element={<SharedBoardsFront />} />
              <Route path="/agents-detail" element={<SharedBoardFrontDetail />} />
              <Route path="/marketplace-detail" element={<MarketplaceDetail />} />
              <Route path="/agent-board-list" element={isAuthenticated ? <AgentBoardList /> : <LoggedOut />} />
              <Route path="/chat-board" element={isAuthenticated ? <NodeAgentChatBoard /> : <LoggedOut />} />
              <Route path="/agents-list" element={isAuthenticated ? <SharedBoardsDisplay /> : <LoggedOut />} />
              <Route path="/agents-list-detail" element={isAuthenticated ? <SharedBoardDetail /> : <LoggedOut />} />
              <Route path="/node-creator-headless" element={isAuthenticated ? <NodeCreatorHeadless /> : <LoggedOut />} />
              <Route path="/privacy" element={<Privacy />} /> {/* Privacy route */}
              <Route path="/tos" element={<TermsOfService />} /> {/* TOS route */}
            </Routes>
          </Suspense>
        </div>

        {/* Footer */}
        {!isAuthenticated && <Footer />} {/* Display Footer only for logged-out users */}
      </div>
    </Router>
  );
};

const AppWrapper = () => {
  return (
    <ReactFlowProvider>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE} // Add your API identifier here
        scope="read:current_user" // Adjust scopes as needed
      >
        <AuthProvider> {/* Wrap with AuthProvider */}
          <App />
        </AuthProvider>
      </Auth0Provider>
    </ReactFlowProvider>
  );
};

export default AppWrapper;
import React, { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isAuthenticated, logout, user, loginWithRedirect } = useAuth0();
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
  const [userAccess, setUserAccess] = useState(localStorage.getItem('userAccess') || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        if (isAuthenticated && user) {
          // Example: Fetch user data from backend if needed
          const fetchedUserId = user.sub; // Unique identifier from Auth0 user data
          const fetchedUserAccess = "standard"; // Example access level from backend

          setUserId(fetchedUserId);
          setUserAccess(fetchedUserAccess);

          // Save to localStorage
          localStorage.setItem('userId', fetchedUserId);
          localStorage.setItem('userAccess', fetchedUserAccess);
        }
      } catch (err) {
        setError('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    }

    if (isAuthenticated && !userId) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, user, userId]);

  // Central logout function that ensures a redirect to the logout screen
  const handleLogout = () => {
    logout({ returnTo: 'https://nodecodestudio.com' });
    setUserId(null);
    setUserAccess(null);
    localStorage.removeItem('userId');
    localStorage.removeItem('userAccess');
  };

  // Login function (optional) if you want to trigger login directly from context
  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <AuthContext.Provider
      value={{
        userId,
        userAccess,
        loading,
        error,
        login: handleLogin,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Docs.css';
import { Spinner, Alert, Form } from 'react-bootstrap';
import { EditOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';

const Docs = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [userAccess, setUserAccess] = useState(localStorage.getItem('userAccess') || '');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const article = params.get('article');
    if (article && documents.length > 0) {
      const doc = documents.find(d => d.permalink === article);
      setSelectedDoc(doc || null);
    } else {
      setSelectedDoc(null);
    }
  }, [location.search, documents]);

  const fetchDocuments = async () => {
    try {
      const response = await fetch('https://nodecodestudio.com/backend/docs_endpoint.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch documents');
      }
      const data = await response.json();
      setDocuments(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownChange = (event) => {
    const selectedPermalink = event.target.value;
    if (selectedPermalink) {
      navigate(`/docs?article=${selectedPermalink}`);
    }
  };

  const getSeoDescription = () => {
    if (selectedDoc && selectedDoc.content) {
      const plainTextContent = DOMPurify.sanitize(selectedDoc.content, { ALLOWED_TAGS: [] });
      return plainTextContent.length > 160
        ? `${plainTextContent.substring(0, 157)}...`
        : plainTextContent;
    }
    return 'Explore our extensive documentation for guidance on various topics.';
  };

  const renderSidebar = () => {
    const categories = [...new Set(documents.map(doc => doc.category))];
    return (
      <div className="sidebar">
        {userAccess === 'advanced' && (
          <Link to="/admin-docs" className="admin-link">
            Admin Panel
          </Link>
        )}
        {categories.map(category => (
          <div key={category}>
            <h5>{category}</h5>
            {documents
              .filter(doc => doc.category === category)
              .map(doc => (
                <Link
                  key={doc.id}
                  to={`/docs?article=${doc.permalink}`}
                  className={`list-group-item list-group-item-action ${
                    selectedDoc && selectedDoc.id === doc.id ? 'active' : ''
                  }`}
                >
                  {doc.title}
                </Link>
              ))}
          </div>
        ))}
      </div>
    );
  };

  const renderDropdown = () => {
    const categories = [...new Set(documents.map(doc => doc.category))];
    return (
      <Form.Select
        className="docs-dropdown"
        onChange={handleDropdownChange}
        aria-label="Select Documentation"
      >
        <option value="">Select a document</option>
        {categories.map(category => (
          <optgroup key={category} label={category}>
            {documents
              .filter(doc => doc.category === category)
              .map(doc => (
                <option key={doc.id} value={doc.permalink}>
                  {doc.title}
                </option>
              ))}
          </optgroup>
        ))}
      </Form.Select>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="text-center my-5">
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }

    if (error) {
      return (
        <Alert variant="danger" className="my-5">
          {error}
        </Alert>
      );
    }

    if (selectedDoc) {
      return (
        <div className="main-content-docs">
          <Helmet>
            <title>{selectedDoc.title} - Node Code Studio Docs</title>
            <meta name="description" content={getSeoDescription()} />
          </Helmet>
          <h2 className="docs-title">
            {selectedDoc.title}
            {userAccess === 'advanced' && (
              <Link
                to={`/admin-docs?id=${selectedDoc.id}`}
                className="ms-2 edit-icon"
                title="Edit Article"
              >
                <EditOutlined style={{ fontSize: '20px', color: '#007bff' }} />
              </Link>
            )}
          </h2>
          <div
            className="docs-content"
            dangerouslySetInnerHTML={{ __html: selectedDoc.content }}
          />
        </div>
      );
    } else {
      return (
        <div className="main-content-docs">
          <Helmet>
            <title>Documentation Home</title>
            <meta name="description" content="Explore our extensive documentation for guidance on various topics." />
          </Helmet>
          <h2 className="docs-title">Welcome to the Documentation</h2>
          <p className="docs-content">Select a topic from the sidebar to get started.</p>
        </div>
      );
    }
  };

  return (
    <div className="docs-container">
      <div className="docs-wrapper">
        {renderSidebar()}
        {renderDropdown()}
        {renderContent()}
      </div>
    </div>
  );
};

export default Docs;